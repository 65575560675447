.block {
    display: flex;
}

.card {
    height: 440px;
    width: 380px;
    background-color: #081E2C;
    border-radius: 8px;
    box-shadow: -1rem 0 1rem #00000080;
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
}

.card:not(:first-child) {
    margin-left: -40px;
}

.card:hover {
    /* transform: translateY(-20px); */
    transform: translate(-30px, -20px);
    transition: 0.4s ease-out;
}

.card:hover~.card {
    position: relative;
    left: 30px;
    transition: 0.4s ease-out;
}

.title {
    font-size: 22px;
    color: black;
    font-weight: 300;
    top: 15px;
    background-color: #F5F5F5DB ;
    padding: 8px;
    box-shadow: -1rem 0 1rem #00000080;
}

.card:hover .filledbar {
    width: 120px;
    transition: 0.4s ease-out;
}

.card:hover {
    stroke-dashoffset: 100;
    transition: 0.6s ease-out;
}

.content {
    color: whitesmoke;
    margin: 15px;
    font-size: 19px;
}

.github {
    color: #F5F5F580;
    /* text-align: right; */
    position: absolute;
    bottom: 0px;
    /* margin: 10px; */
    margin-left: 20px;
    margin-bottom: 10px;
}

a {
    text-decoration: none;
    margin: 0px;
    color: #F5F5F580;
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='55' viewport='0 0 100 100' style='fill:black;font-size:25px;'><text y='50%'>🍭</text></svg>"), auto;
}

.tag {
    color: #F5F5F580;
    margin-bottom: 22px;
    margin-top: -15px;
}

