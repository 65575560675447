.slide {
    width: 100vw;
    height: 100vh;  
    scroll-snap-align: center;
}

.preSlide {
    background: #E74C3C ;
}

.one {
    background: whitesmoke;
}

.two {
    background: #081E2C;
}

.three {
    background: whitesmoke;
}

.four {
    background: #081E2C;
}

.five {
    background: whitesmoke;
}

.six {
    background: whitesmoke;
}

.wrapper {
    display: flex;
    flex-direction: row;
    width: 700vw;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
}

.outer-wrapper {
    width: 100vh;
    height: 100vw;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
}

.outer-wrapper {
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-snap-type: y mandatory;
}
